.contact.header {
  background-image: url("images/Contact_Header.jpg");
}

.marker {
  position: absolute;
  padding: 4%;
  margin: -4%;
  background: #ed1c2477;
  border-radius: 50%;
  transition: all 0.25s;
  width: 1.30890052356%;
  height: 1.85873605948%;
}

.marker:hover {
  background: #ed1c24cc;
}
