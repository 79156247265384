.navbar.transparent {
  background-color: unset;
}

.transparent-navbar {
  transition: padding 0.2s ease-in-out;
}

.navbar {
  transition: padding 0.2s ease-in-out;
  padding: 8px;
}

.navbar.padding {
  padding: 48px 0px 0px 0px;
}

.navbar > * .logo {
  background-image: url("images/logo-aone.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  margin-top: -20px;
  margin-bottom: -20px;
  margin-right: 16px;
  transition: all 0.5s ease-in-out;
  min-width: 150px;
}

.navbar.transparent:not(.mobile) > * .logo {
  height: 80px;
  margin-top: -30px;
  margin-bottom: -30px;
  transition: 0.5s;
  min-width: 200px;
}

.navbar.menu {
  background-color: black;
}
