body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Display flex by default */

html,
body,
div {
  display: flex;
  box-sizing: border-box;
  align-items: stretch;
  flex-direction: column;
}

html {
  height: 100%;
}

html,
body,
#root {
  flex-grow: 1;
}

#root {
  overflow: hidden;
}

/* Defaults */

a {
  color: inherit;
  text-decoration: inherit;
}

/* Flex */

.f-1 {
  flex: 1;
}

.f-s {
  flex-shrink: 99;
}

.f-row {
  flex-direction: row;
}

.f-row-reverse {
  flex-direction: row-reverse !important;
}

.f-col {
  flex-direction: column;
}

.f-col-reverse {
  flex-direction: column-reverse !important;
}

.f-center {
  justify-content: center;
  align-items: center;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-end {
  justify-content: flex-end;
}

.ai-center {
  align-items: center;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.as-center {
  align-self: center;
}

.as-start {
  align-self: start;
}

.as-end {
  align-self: end;
}

.fw-wrap {
  flex-wrap: wrap;
}

/* Position */

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

/* Spacing */

[class^="row"] {
  flex-direction: row;
}

/* Font */

.fw-bold {
  font-weight: bold;
}

.fs-italic {
  font-style: italic;
}

.ta-center {
  text-align: center;
}

.ta-justify {
  text-align: justify;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.tal-center {
  text-align-last: center;
}

.tal-left {
  text-align-last: left;
}

.tal-right {
  text-align-last: right;
}

.tt-uppercase {
  text-transform: uppercase;
}

.tt-capitalize {
  text-transform: capitalize;
}

/* Others */

.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* To sort */

.hover-accent {
  transition: all 0.2s;
}

.hover-accent:hover {
  color: var(--accent-color);
}

.bg-none {
  background-color: unset;
}

.bg-test {
  background-color: var(--accent-400-color);
}

.pe-none {
  pointer-events: none;
}

.pe-all {
  pointer-events: all;
}

.of-hidden {
  overflow: hidden;
}

.of-auto {
  overflow: auto;
}

.t-0 {
  top: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}

.l-0 {
  left: 0;
}

.grid {
  display: grid;
}

.zi-0 {
  z-index: 0 !important ;
}

.zi-1 {
  z-index: 1 !important ;
}

.zi-2 {
  z-index: 2 !important ;
}

.zi--1 {
  z-index: -1;
}

.t-s--1 {
  transform: scale(-1);
}

.t-r-0 {
  transform: rotate(0deg);
}

.t-r-180 {
  transform: rotate(180deg);
}

.v-flip {
  transform: rotate(0deg);
  transition: transform 0.25s;
}

.v-flip.active {
  transform: rotate(180deg);
}

.full-rect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.of-contain {
  object-fit: contain;
}

.of-cover {
  object-fit: cover;
}

.o-0 {
  opacity: 0;
}

.d-ltr {
  direction: ltr;
}

.d-rtl {
  direction: rtl;
}

.fast-spin {
  -webkit-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
}
