@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

$sizes: (
  0,
  2,
  4,
  8,
  12,
  16,
  18,
  20,
  24,
  28,
  32,
  36,
  40,
  48,
  64,
  72,
  96,
  128,
  192,
  224,
  256
);
$percentages: (0, 25, 50, 100);
$positions: (
  "t": "top",
  "r": "right",
  "b": "bottom",
  "l": "left",
);

$black: #000000;
$black-h: #00000088;
$white: #ffffff;
$white-h: #ffffff88;
$accent: #ed1c24; // TODO: This should be overwritten for each project
$rebeccapurple: rebeccapurple;
$orange: orange;
$dark-gray: #212121;

$colors: (
  "black": $black,
  "black-h": $black-h,
  "white": $white,
  "white-h": $white-h,
  "accent": $accent,
  "rebeccapurple": $rebeccapurple,
  "orange": $orange,
  "dark-gray": $dark-gray,
);

// Padding
// p-{size}
// p{position}-{size}
// pc{position}-{size}
// m{position}-{size}
// mc{position}-{size}

@each $size in $sizes {
  .p-#{$size} {
    padding: #{$size}px !important;
  }
  @each $key, $position in $positions {
    .p#{$key}-#{$size} {
      padding-#{$position}: #{$size}px !important;
    }
  }
  .px-#{$size} {
    padding-right: #{$size}px !important;
    padding-left: #{$size}px !important;
  }
  .py-#{$size} {
    padding-top: #{$size}px !important;
    padding-bottom: #{$size}px !important;
  }

  // Negative

  .p--#{$size} {
    padding: -#{$size}px !important;
  }
  @each $key, $position in $positions {
    .p#{$key}--#{$size} {
      padding-#{$position}: -#{$size}px !important;
    }
  }
  .px--#{$size} {
    padding-right: -#{$size}px !important;
    padding-left: -#{$size}px !important;
  }
  .py--#{$size} {
    padding-top: -#{$size}px !important;
    padding-bottom: -#{$size}px !important;
  }

  // Children

  .pc-#{$size} > * {
    padding: #{$size}px !important;
  }
  @each $key, $position in $positions {
    .pc#{$key}-#{$size} > * {
      padding-#{$position}: #{$size}px !important;
    }
  }
  .pcx-#{$size} > * {
    padding-right: #{$size}px !important;
    padding-left: #{$size}px !important;
  }
  .pcy-#{$size} > * {
    padding-top: #{$size}px !important;
    padding-bottom: #{$size}px !important;
  }

  .m-#{$size} {
    margin: #{$size}px !important;
  }
  @each $key, $position in $positions {
    .m#{$key}-#{$size} {
      margin-#{$position}: #{$size}px !important;
    }
  }
  .mx-#{$size} {
    margin-right: #{$size}px !important;
    margin-left: #{$size}px !important;
  }
  .my-#{$size} {
    margin-top: #{$size}px !important;
    margin-bottom: #{$size}px !important;
  }

  // Negative

  .m--#{$size} {
    margin: -#{$size}px !important;
  }
  @each $key, $position in $positions {
    .m#{$key}--#{$size} {
      margin-#{$position}: -#{$size}px !important;
    }
  }
  .mx--#{$size} {
    margin-right: -#{$size}px !important;
    margin-left: -#{$size}px !important;
  }
  .my--#{$size} {
    margin-top: -#{$size}px !important;
    margin-bottom: -#{$size}px !important;
  }

  // Children

  .mc-#{$size} > * {
    margin: #{$size}px !important;
  }
  @each $key, $position in $positions {
    .mc#{$key}-#{$size} > * {
      margin-#{$position}: #{$size}px !important;
    }
  }
  .mcx-#{$size} > * {
    margin-right: #{$size}px !important;
    margin-left: #{$size}px !important;
  }
  .mcy-#{$size} > * {
    margin-top: #{$size}px !important;
    margin-bottom: #{$size}px !important;
  }
}

// Font

@each $size in $sizes {
  .fs-#{$size} {
    font-size: #{$size}px;
  }
}

// Color

$tints: (100, 200, 300, 400, 500, 600, 700, 800, 900);

:root {
  @each $name, $color in $colors {
    --#{$name}-color: #{$color};
    --#{$name}-100-color: #{tint-color($color, 80%)};
    --#{$name}-200-color: #{tint-color($color, 60%)};
    --#{$name}-300-color: #{tint-color($color, 40%)};
    --#{$name}-400-color: #{tint-color($color, 20%)};
    --#{$name}-500-color: #{$color};
    --#{$name}-600-color: #{shade-color($color, 20%)};
    --#{$name}-700-color: #{shade-color($color, 40%)};
    --#{$name}-800-color: #{shade-color($color, 60%)};
    --#{$name}-900-color: #{shade-color($color, 80%)};
  }
}

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: #{$color};
  }
  @each $tint in $tints {
    .bg-#{$name}-#{$tint} {
      background-color: var(--#{$name}-#{$tint}-color);
    }
  }
  .cl-#{$name} {
    color: #{$color};
  }
  svg.cl-#{$name} {
    fill: #{$color};
  }
}

// Width / Height

@each $percentage in $percentages {
  .w-#{$percentage} {
    width: #{$percentage + "%"};
  }
  .h-#{$percentage} {
    height: #{$percentage + "%"};
  }
  .mnw-#{$percentage} {
    min-width: #{$percentage + "%"};
  }
  .mnh-#{$percentage} {
    min-height: #{$percentage + "%"};
  }
  .mxw-#{$percentage} {
    max-width: #{$percentage + "%"};
  }
  .mxh-#{$percentage} {
    max-height: #{$percentage + "%"};
  }
}

// Rows / Cols

@each $size in $sizes {
  .row-m#{$size},
  .row-p#{$size} {
    flex-direction: row !important;
  }

  .row-m#{$size} > *:not(:first-child) {
    margin-left: #{$size}px;
  }

  .row-p#{$size} > *:not(:first-child) {
    padding-left: #{$size}px;
  }

  .row-m#{$size}-r,
  .row-p#{$size}-r {
    flex-direction: row-reverse !important;
  }

  .row-m#{$size}-r > *:not(:last-child) {
    margin-left: #{$size}px;
  }

  .row-p#{$size}-r > *:not(:last-child) {
    padding-left: #{$size}px;
  }

  .col-m#{$size},
  .col-p#{$size} {
    flex-direction: column !important;
  }

  .col-m#{$size} > *:not(:first-child) {
    margin-top: #{$size}px;
  }

  .col-p#{$size} > *:not(:first-child) {
    padding-top: #{$size}px;
  }

  .col-m#{$size}-r,
  .col-p#{$size}-r {
    flex-direction: column-reverse !important;
  }

  .col-m#{$size}-r > *:not(:last-child) {
    margin-top: #{$size}px;
  }

  .col-p#{$size}-r > *:not(:last-child) {
    padding-top: #{$size}px;
  }
}

// Gap

@each $size in $sizes {
  .g-#{$size} {
    gap: #{$size}px;
  }
}

@each $size in $sizes {
  .br-#{$size} {
    border-radius: #{$size}px;
  }
}
