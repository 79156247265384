.navbar {
  width: 100%;
  z-index: 1000;
}

.navbar > * .logo {
  height: 50px;
}

.navbar.menu {
  position: fixed;
  height: 100%;
}
