.fade-1 {
  animation: fade 1s cubic-bezier(0.23, 1, 0.32, 1) 0.25s backwards;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
