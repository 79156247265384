.btn {
  cursor: pointer;
  clip-path: polygon(
    0 8px,
    8px 0,
    calc(100% - 8px) 0,
    100% 8px,
    100% 100%,
    8px 100%,
    0% calc(100% - 8px),
    0% 8px
  );
}
