.header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.header::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("images/GridPattern.png");
  background-repeat: repeat;
  background-size: 4px;
  z-index: 0;
  opacity: 0.8;
  position: absolute;
}

@media only screen and (max-width: 760px) {
  .header::after {
    background-size: 2px;
  }
}

.header > * {
  z-index: 1;
}

.bg-omen,
.bg-nemo,
.bg-services,
.bg-business {
  position: relative;
  z-index: 0;
}

.bg-omen::before,
.bg-nemo::before,
.bg-services::before,
.bg-business::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%)
    contrast(0%); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.bg-omen::before {
  background-image: url("images/logo-omen-symbol.svg");
}

.bg-nemo::before {
  background-image: url("images/icons/support.svg");
}

.bg-services::before {
  background-image: url("images/icons/services.svg");
}

.bg-business::before {
  background-image: url("images/icons/business.svg");
}

.logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.logo.nemo {
  background-image: url("images/Nemo_NuWht-min.png");
  width: 100%;
  height: 300px;
}

.logo.aone {
  background-image: url("images/logo-aone.svg");
  width: 100%;
  height: 200px;
}

.notification {
  background-color: #ed1c24;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  margin-left: -6px;
  margin-top: -20px;
  color: white;
}
