.card {
  position: relative;
  clip-path: polygon(
    0 16px,
    16px 0,
    calc(100% - 16px) 0,
    100% 16px,
    100% 100%,
    16px 100%,
    0% calc(100% - 16px)
  );

  animation: show 0.75s cubic-bezier(0.23, 1, 0.32, 1) both;

  transform-origin: 0 0;
  background-color: var(--accent-700-color);

  text-shadow: 0 0 8px black, 0 0 8px black, 0 0 8px black, 0 0 8px black,
    0 0 8px black, 0 0 8px black, 0 0 8px black;
}

.card > .body {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.25s;
  transform-origin: 0 0;
  animation: show-clip 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.25s backwards;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

@keyframes show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes show-clip {
  from {
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  to {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
}

.card:hover > .body {
  clip-path: polygon(
    0 0,
    0 calc(100% - 16px),
    calc(100% - 16px) calc(100% - 16px),
    calc(100% - 16px) 0
  );
}
